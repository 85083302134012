.footer-block {
    background-color: #253E1E;
    display: flex;
    padding: 0.5rem;
    align-items: flex-start;
    height: auto;
}

.footer {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.footer-link {
    color: white;
    text-underline-offset: 5px;
}

.divider {
    margin-right: 5vw;
    margin-left: 5vw;
}

.socials-content {
    margin: auto;
    /* margin-left: 5vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    /* margin-bottom: -3vh; */
}

.socials-icon {
    object-fit:scale-down;
    /* height: 120%; */
    filter: invert(100%) sepia(38%) saturate(0%) hue-rotate(163deg) brightness(111%) contrast(100%);
}

.socials-icon {
    width: 35px;
    height: 35px;
    margin: 5px;
}

.highlighted:hover {
  color: gray;
  filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
  transform: scale(1.1);
}

.highlighted-text:hover {
  filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
  text-decoration: underline;
}

@media screen and (max-aspect-ratio: 6/5)
{
    .divider, .unecessary-footer-text {
        display: none;
    }
    .footer {
        flex-direction: column;
    }
    .footer-text {
        text-align: center;
    }
}