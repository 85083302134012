body, input[type="text"] {
  color: white;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1 .h2 .h3 .h4 .p {
  color: white;
}

.heading {
  font-size: 40pt;
  color: white;
  margin-top: 0;
}

.block {
  background-color: #42654A;
  overflow: hidden;
  padding: 4rem;
}

@media screen and (max-aspect-ratio: 6/5) {
  .block {
    padding: 2rem;
  }
}
