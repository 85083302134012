.typing {
    font-size: 1.6rem;
    height: 100px;
}

.logo {
    width: 30%;
    max-height: 35vh;
}

@media screen and (max-aspect-ratio: 6/5) {
    .typing {
        font-size: 1.2rem;
        height: 120px;
    }
    .logo {
        display: none;
    }
}
 