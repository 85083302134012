.nav {
  top: 0px;
  padding: 0.25rem 0.5rem;
  background-color: #253E1E;
  position: sticky;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-weight: bold;
}

.nav-items {
  padding: 0 0.5rem;
  list-style: none;
  text-align: center;
  font-weight: 600;
}

.nav-item {
  display: inline-block;
  color: white;
}

.nav-item:not(:first-child) {
  margin-left: 3vw;
}

.nav-item-selected, .nav-item:hover {
  color: rgb(235, 251, 216);
  cursor: pointer;
}

.anchor-base,
.anchor-base:hover,
.anchor-base:visited,
.anchor-base:focus,
.anchor-base:active {
  text-decoration: none !important;
  color: inherit !important;
  outline: 0;
  cursor: pointer;
}

@media screen and (max-width: 800px)
{
  .nav {
    justify-content: center;
  }
  .nav-item:hover {
    text-underline-offset: 3px;
  }
  .nav-logo {
    display: none;
  }
}
